<template>
  <v-text-field
    v-model="date"
    :label="label"
    :placeholder="placeholder"
    :prepend-inner-icon="prependIcon"
    :dense="dense"
    :required="required"
    :rules="mergedRules"
    type="date"
    @change="handleInputDate"
  >
  </v-text-field>
</template>

<script>
export default {
  props: {
    isBirthDate: { type: Boolean, default: false },
    value: { type: String, default: "" },
    label: { type: String, default: "Fecha" },
    dense: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    max: {
      type: String,
      default: function () {
        return this.isBirthDate
          ? this.$moment().format("YYYY-MM-DD")
          : this.$moment().add(100, "years").format("YYYY-MM-DD");
      },
    },
    min: { type: String, default: "1900-01-01" },
    firstDayOfWeek: { type: Number, default: 1 },
    placeholder: { type: String, default: "DD/MM/AAAA" },
    prependIcon: { type: String, default: "mdi-calendar" },
    rules: { type: Array, default: () => [] },
  },
  data: () => ({
    date: null,
    datePickerActive: null,
    mergedRules: [],
  }),
  methods: {
    handleInputDate(newValue) {
      this.$emit("change", newValue);
    },
  },
  mounted() {
    this.date = this.value;
    let rules = [];
    if (this.required) {
      rules = [
        v => {
          return this.$moment(v).isAfter(this.min) || "Mín. " + this.$moment(this.min).format("L");
        },
        v => {
          return this.$moment(v).isBefore(this.max) || "Máx. " + this.$moment(this.max).format("L");
        },
      ];
    }
    this.mergedRules = [...rules, ...this.rules];
  },
  watch: {
    date: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
